<template>
  <el-dialog
    top="80px"
    width="500px"
    class="group-item"
    :title="!item ? `Tạo mới item cho ${group.name}` : 'Cập nhật item'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Loại" prop="object_type">
        <el-select
          v-model="form.object_type"
          filterable
          :disabled="callingAPI || loading"
          @change="handleChangeType"
        >
          <el-option v-for="c in objectTypes" :key="c.value" :label="c.name" :value="c.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="`Chọn ${object.name.toLowerCase()}`" prop="object_id">
        <el-select
          v-model="form.object_id"
          filterable
          remote
          :disabled="callingAPI || loading"
          :remote-method="searchObject"
          :loading="searching"
        >
          <el-option v-for="c in objects" :key="c.id" :label="c.name" :value="c.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button :disabled="callingAPI || loading" @click="$emit('close')">Hủy</el-button>
      <el-button
        type="primary"
        :loading="callingAPI || loading"
        @click="handleSaveItem"
      >{{ item ? "Cập nhật" : "Tạo mới" }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createItem, updateItem } from '@/services/group'
import { listPlaces } from '@/services/place'
import { getPlaceTypes } from '@/utils/filters'

export default {
  name: 'GroupItem',
  props: {
    visible: Boolean,
    group: Object,
    item: Object,
    type: String
  },
  data() {
    return {
      callingAPI: false,
      loading: false,
      searching: false,
      objects: [],
      objectTypes: getPlaceTypes(),
      searchText: '',
      form: {
        object_id: '',
        object_type: ''
      },
      rules: {
        object_id: [
          {
            required: true,
            message: 'Địa điểm không được để trống.',
            trigger: ['blur, change']
          }
        ],
        object_type: [
          {
            required: true,
            message: 'Loại không được để trống.',
            trigger: ['blur, change']
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    object() {
      return this.objectTypes.find((t) => t.value === this.form.object_type)
    },
    createItemInput() {
      return {
        group_id: this.group.id,
        locale: this.language,
        object_id: this.form.object_id,
        object_type: this.object ? this.object.type : ''
      }
    },
    updateItemInput() {
      return {
        id: this.item ? this.item.id : '',
        locale: this.language,
        object_id: this.form.object_id,
        object_type: this.object ? this.object.type : ''
      }
    },
    params() {
      return {
        locale: this.language,
        place_types: this.object.value,
        per_page: 10,
        search_text: this.searchText
      }
    }
  },
  beforeMount() {
    this.loadData()
  },
  methods: {
    loadData() {
      if (!this.item) {
        this.form.object_type = this.objectTypes[0].value
        this.getObjects()
      } else {
        this.form.object_type = this.item.object.place_type
        this.form.object_id = this.item.object.id
        this.searchText = this.item.object.name
        this.getObjects()
      }
    },
    handleChangeType() {
      this.form.object_id = ''
      this.getObjects()
    },
    searchObject(text) {
      this.searchText = text
      this.searching = true
      this.getObjects(false)
    },
    getObjects(loading = true) {
      this.loading = loading
      const request = listPlaces(this.params)
      request.then((res) => {
        this.objects = res.result
        this.loading = false
        this.searching = false
      }).catch(() => {
        this.loading = false
        this.searching = false
      })
    },
    handleSaveItem() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.callingAPI = true
          let request = null
          if (this.item) {
            request = updateItem(this.updateItemInput, this.type)
          } else {
            request = createItem(this.createItemInput, this.type)
          }
          request
            .then(() => {
              this.callingAPI = false
              this.$emit('close')
              this.$emit('reload')
              this.$notify.success({
                title: 'Thông báo',
                message: `${this.item ? 'Cập nhật item' : 'Tạo mới item'} thành công`
              })
            })
            .catch((error) => {
              this.callingAPI = false
              this.$notify.error({
                title: 'Thông báo',
                message: error && error.response && error.response.data && error.response.data.message || 'Lỗi hệ thống'
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.group-item {
  .el-select {
    width: 100%;
  }
}
</style>
