<template>
  <div class="parent one-component-detail-admin">
    <div class="title-component-admin">Địa danh / Điểm đến</div>
    <el-form :model="data" class="p-3">
      <el-form-item label="Loại điểm đến">
        <el-select
          v-model="placeType"
          filterable
          class="w-100"
          @change="handleChangePlaceType"
        >
          <el-option v-for="t in placeTypes" :key="t.value" :label="t.name" :value="t.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Điểm đến">
        <el-select
          v-model="data.post_place_id"
          class="w-100"
          filterable
          remote
          placeholder="Chọn điểm đến"
          :remote-method="searchPlace"
          :loading="searching"
        >
          <el-option v-for="p in places" :key="p.id" :label="p.name" :value="p.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getTranslation, getPlaceTypes } from '@/utils/filters'
import { listPlaces, getPlaceById } from '@/services/place'

export default {
  name: 'Place',
  props: {
    data: Object,
    type: String
  },
  data() {
    return {
      searchText: '',
      searching: false,
      places: [],
      placeType: ''
    }
  },
  computed: {
    ...mapGetters(['constant', 'language']),
    placeTypes() {
      return getPlaceTypes(false).filter((p) => p.type === 'place')
    },
    params() {
      return {
        locale: this.language,
        place_types: this.placeType,
        per_page: 10,
        search_text: this.searchText
      }
    }
  },
  async beforeMount() {
    if (this.data.post_place_id) {
      const postPlace = await getPlaceById({
        id: this.data.post_place_id,
        locale: this.language
      })
      this.placeType = postPlace.place_type
      this.places = [postPlace]
    } else if (this.data.object_id && this.data.object) {
      this.placeType = this.data.object.place_type
      this.places = [
        { id: this.data.object_id, name: this.data.object.name || (getTranslation(this.data.object) ? getTranslation(this.data.object).name : '') }
      ]
    }
  },
  methods: {
    getPlaces() {
      this.searching = true
      const request = listPlaces(this.params)
      request.then((res) => {
        this.places = res.result
        this.searching = false
      }).catch(() => {
        this.searching = false
      })
    },
    handleChangePlaceType() {
      this.data.place_id = ''
      this.data.object_id = ''
      this.searchText = ''
      this.getPlaces()
    },

    searchPlace(text) {
      this.searchText = text
      setTimeout(() => {
        this.getPlaces()
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped></style>
