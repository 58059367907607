<template>
  <div class="category one-component-detail-admin">
    <div class="title-component-admin">Danh mục</div>
    <el-form :model="data" class="p-3">
      <el-form-item label="Chọn danh mục">
        <el-select v-model="data.category_id">
          <el-option v-for="c in categories" :key="c.id" :label="c.name" :value="c.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TYPE from '@/data/type'

export default {
  name: 'Category',
  props: {
    data: Object,
    type: String
  },
  computed: {
    ...mapGetters(['constant']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    categories() {
      return this.constant.constants.place_categories.filter(
        (c) => c.place_type === this.type
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  .el-select {
    width: 100%;
  }
}
</style>
