<template>
  <el-form :key="info.key" class="editor-type" :model="data" label-position="top">
    <div v-if="info.image">
      <p class="mb-0">
        <b>{{ info.label }}</b>
      </p>
      <el-row :gutter="24">
        <el-col :span="8">
          <el-form-item label="Ảnh" :prop="info.image">
            <div v-loading="uploading" class="background-group__item">
              <div v-if="data[info.image_info]">
                <img
                  :src="data[info.image_info].url"
                  alt="href"
                  style="height: 117px"
                />
              </div>
              <div v-else>
                <img
                  src="@/assets/images/default-image.jpeg"
                  alt="href"
                  style="height: 117px"
                />
              </div>
              <div class="buttons">
                <upload-file
                  :type="data.place_type"
                  :file-type="'image'"
                  :object-type="objectType"
                  @uploading="() => uploading = true"
                  @uploaded="() => uploading = false"
                  @update="(file) => handleUpdateFile(file)"
                  @delete="deleteOldFile()"
                >
                  <el-button size="mini" type="primary" plain>
                    {{ !data[info.image_info] ? "Tải" : "Đôi" }}
                  </el-button>
                </upload-file>
                <el-button
                  v-if="data[info.image_info]"
                  size="mini"
                  type="danger"
                  class="ml-2"
                  plain
                  @click.native="deleteFile()"
                >
                  Xoá
                </el-button>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="Mô tả" :prop="info.description">
            <el-input
              v-model="data[info.description]"
              placeholder="Nhập thông tin"
              clearable
              type="textarea"
              :rows="5"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <el-form-item :label="info.image ? 'Thông tin chi tiết' : info.label" :prop="info.key">
      <editor
        :editor-key="info.value"
        :value="data[info.key]"
        @change="(v) => data[info.key] = v"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import Editor from '../items/Editor.vue'
import UploadFile from '../items/UploadFile'
import { deleteStorages } from '@/services/storage'

export default {
  name: 'EditorType',
  components: {
    Editor,
    UploadFile
  },
  props: {
    info: Object,
    data: Object
  },
  data() {
    return {
      uploading: false
    }
  },
  computed: {
    objectType() {
      if (['artifact', 'dish', 'dish_group'].includes(this.data.place_type)) {
        return this.data.place_type
      }
      return 'place'
    }
  },
  methods: {
    async deleteOldFile() {
      if (this.data[this.info.image_info]) {
        await deleteStorages({
          file_type: 'image',
          object_type: this.objectType,
          ids: this.data[this.info.image_info].id
        })
          .then(() => {
            this.data[this.info.image] = null
            this.data[this.info.image_info] = null
          })
          .catch(() => {})
      } else {
        this.data[this.info.image] = null
        this.data[this.info.image_info] = null
      }
      await this.$emit('update', false)
    },
    deleteFile(item) {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        this.deleteOldFile(item)
      })
    },
    handleUpdateFile(file) {
      this.data[this.info.image_info] = file
      this.data[this.info.image] = file.id
      this.$emit('update', false)
      this.uploading = false
    }
  }
}
</script>

<style lang="scss">
.editor-type {
  .ql-container {
    max-height: 300px;
    overflow: auto;
  }
  .ql-editor {
    color: #606266;
    font-size: 14px;
    font-family: inherit;
  }
}
</style>
