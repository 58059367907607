<template>
  <el-dialog
    top="50px"
    :visible="visible"
    title="Thêm lịch trình"
    width="700px"
    close-on-press-escape
    close-on-click-modal
    @close="handleClose"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="Thời gian (Số giờ)" prop="time">
        <el-input v-model.number="form.time" type="number" placeholder="Nhập vào thời gian"></el-input>
      </el-form-item>
      <el-form-item label="Quãng đường (Km)" prop="distance">
        <el-input v-model.number="form.distance" type="number" placeholder="Nhập vào quãng đường"></el-input>
      </el-form-item>
      <el-form-item prop="description">
        <p>Mô tả</p>
        <editor :value="form.description" @change="(v) => form.description = v" />
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
      <el-button type="primary" :loading="callingAPI" @click="handleSaveInfo">Lưu</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { createSchedule, updateSchedule } from '@/services/tour'
import Editor from '../items/Editor.vue'

export default {
  name: 'Schedule',
  components: {
    Editor
  },
  props: {
    visible: Boolean,
    data: Object
  },
  data() {
    return {
      callingAPI: false,
      form: {
        time: this.data ? this.data.time : 0,
        distance: this.data ? this.data.distance : 0,
        description: this.data ? this.data.description : ''
      },
      rules: {
        time: [
          { required: true, message: 'Giá trị không được để trống', trigger: ['blur', 'change'] },
          { type: 'number', message: 'Giá trị phải là số', trigger: ['blur', 'change'] }
        ],
        distance: [
          { required: true, message: 'Giá trị không được để trống', trigger: ['blur', 'change'] },
          { type: 'number', message: 'Giá trị phải là số', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  methods: {
    handleSaveInfo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.data) {
            this.updateSchedule()
          } else {
            this.createSchedule()
          }
        }
      })
    },
    createSchedule() {
      this.callingAPI = true
      const data = {
        time: Number(this.form.time),
        distance: Number(this.form.distance),
        description: this.form.description,
        locale: this.language,
        place_id: this.$route.params.id
      }
      createSchedule(data).then(() => {
        this.$notify.success({
          title: 'Thông báo',
          message: 'Tạo lịch trình thành công'
        })
        this.handleClose()
        this.$emit('reload')
        this.callingAPI = false
      }).catch(() => {
        this.$notify.error({
          title: 'Thông báo',
          message: 'Tạo lịch trình thất bại'
        })
        this.callingAPI = false
      })
    },

    updateSchedule() {
      this.callingAPI = true
      updateSchedule({ ...this.data, ...this.form }).then(() => {
        this.$notify.success({
          title: 'Thông báo',
          message: 'Cập nhật thành công'
        })
        this.handleClose()
        this.$emit('reload')
        this.callingAPI = false
      }).catch(() => {
        this.$notify.error({
          title: 'Thông báo',
          message: 'Cập nhật trình thất bại'
        })
        this.callingAPI = false
      })
    },

    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__wrapper {
  z-index: 1002 !important;
}
</style>
